<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="头像" prop="header">
        <SelectImgs :selectNum="1" :selectData="avatar" :selectFunc="fileChoose"></SelectImgs>
      </el-form-item>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="info.username" placeholder="请输入用户名" disabled></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model="info.nickname" placeholder="请输入昵称"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="info.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="info.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
export default {
  data() {
    return {
      dialogFormVisible: false,
      info: {
        avatar: '',
        nickname: '',
        email: '',
      },
      avatar: [],
      image_domain: this.config.IMAGE_DOMAIN,
      rules: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
  },
  components: {
    SelectImgs
  },
  computed: {
  },
  methods: {
    getUserInfo() {
      var that = this
      this.$api.merchant.myIndex(function (res) {
        if(res.errcode == 0) {
          that.info = res.data
          if(that.info.avatar) {
            that.avatar = [that.info.avatar]
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = {
            nickname: that.info.nickname,
            avatar: that.info.avatar,
            email: that.info.email,
            mobile: that.info.mobile
          }
          this.$api.merchant.myUpdate(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
    fileChoose(item) {
      this.info.avatar = item.pic
      this.avatar = [item.pic]
    }
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
</style>
